// react
import React, { useState, useEffect, useCallback } from "react";

// react-router-dom
import { useParams } from "react-router-dom";

// @mui
import { Box, Grid, Paper, Typography, CircularProgress } from "@mui/material";

// ui-components
import CustomerDetails from "ui-components/CustomerManagement/CustomerDetails";
import ClientPolicies from "ui-components/CustomerManagement/ClientPolicies";
import EditCustomerPolicy from "ui-components/CustomerManagement/EditCustomerPolicy";

// firebase
import { collection, doc, getDoc, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const ClientSettings = () => {
  const { customer } = useParams();

  const [client, setClient] = useState(null);
  const [agreements, setAgreements] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [openEditClientPolicy, setOpenEditClientPolicy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCustomerData = useCallback(async () => {
    setIsLoading(true);
    try {
      const customerDocRef = doc(db, "clientsdata", customer);
      const customerDocSnap = await getDoc(customerDocRef);

      if (customerDocSnap.exists()) {
        const id = customerDocRef.id;
        const clientData = customerDocSnap.data();
        const agreementsRef = collection(customerDocRef, "agreements");
        const agreementsSnap = await getDocs(agreementsRef);
        const agreementsList = agreementsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClient({ id, ...clientData });
        setAgreements(agreementsList);
      } else {
        console.error("Customer document not found");
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [customer]);

  useEffect(() => {
    fetchCustomerData();

    const customerDocRef = doc(db, "clientsdata", customer);
    const agreementsRef = collection(customerDocRef, "agreements");
    const unsubscribe = onSnapshot(agreementsRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified") {
          fetchCustomerData();
        }
      });
    });
    return () => unsubscribe();
  }, [customer, fetchCustomerData]);

  const clickEdit = (agreement) => {
    setSelectedAgreement(agreement);
    setOpenEditClientPolicy(true);
    window.history.pushState(null, "");
  };

  if (isLoading) {
    return (
      <Grid container justifyContent="center" mt="30%">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {client && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold" color="text.primary" gutterBottom>
              {client.companyName || customer}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3}>
              <Box p={2}>
                <CustomerDetails client={client} />
              </Box>
            </Paper>
          </Grid>
        </>
      )}
      {client && agreements && (
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box p={2}>
              <ClientPolicies client={client} agreements={agreements} clickEdit={clickEdit} />
            </Box>
          </Paper>
        </Grid>
      )}
      {client && selectedAgreement && <EditCustomerPolicy client={client} agreement={selectedAgreement} open={openEditClientPolicy} setOpen={setOpenEditClientPolicy} />}
    </Grid>
  );
};

export default ClientSettings;
