// react
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// react-flagkit
import Flag from "react-flagkit";

// @mui
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LoginIcon from "@mui/icons-material/Login";

// A ---------------------------------------------------------------------- M

// Language Option Component
const LanguageOption = ({ country, label }) => (
  <Box display="flex" alignItems="center">
    <Flag country={country} style={{ marginRight: 8 }} />
    {label}
  </Box>
);

const DefaultLayout = (props) => {
  const { language, setLanguage } = UserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleLanguageChange = (lng) => {
    setLanguage(lng);
  };

  const handleSignIn = () => {
    navigate("/signin");
  };

  const gridItemStyle = isMobile ? { p: "3%" } : { p: "1.5%" };

  const isDefaultable = location.pathname === "/signin" || location.pathname === "/reset-password";

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }} />
            <Select value={language} onChange={(e) => handleLanguageChange(e.target.value)} displayEmpty sx={{ color: "white", ".MuiOutlinedInput-notchedOutline": { border: "none" } }}>
              <MenuItem value="it">
                <LanguageOption country="IT" label="Italiano" />
              </MenuItem>
              <MenuItem value="en">
                <LanguageOption country="GB" label="English" />
              </MenuItem>
            </Select>
            {!isDefaultable && (
              <Tooltip title={t("sign_in")} placement="top">
                <IconButton color="inherit" onClick={handleSignIn}>
                  <LoginIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
        </AppBar>
        {!isDefaultable && <Toolbar />}
      </Grid>
      <Grid item xs={12} sx={!isDefaultable ? gridItemStyle : {}}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default DefaultLayout;
