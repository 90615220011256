// react
import React from "react";

// react-router-dom
import { useNavigate, useLocation } from "react-router-dom";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { useTheme, useMediaQuery, Drawer, Toolbar, List, ListItem, ListItemIcon, ListItemText, Box, Divider } from "@mui/material";
import {
  Archive as ArchiveIcon,
  Groups as GroupsIcon,
  PlaylistAddCheckOutlined as PlaylistAddCheckOutlinedIcon,
  QrCodeScanner as QrCodeScannerIcon,
  Api as ApiIcon,
  VpnKey as KeyIcon,
  PersonAdd as PersonAddIcon,
  Login as LoginIcon,
  Business as BusinessIcon,
  MonitorHeart as MonitorHeartIcon,
} from "@mui/icons-material";

// ui-components
import Copyright from "../../Copyright";

// A ---------------------------------------------------------------------- M

const NavigationItem = ({ onClick, icon, text }) => {
  const theme = useTheme();
  return (
    <ListItem
      onClick={onClick}
      sx={{
        "&:hover": {
          backgroundColor: "#bbdefb",
          cursor: "pointer",
        },
      }}
    >
      <ListItemIcon sx={{ minWidth: 40, color: theme.palette.text.primary }}>{icon}</ListItemIcon>
      <ListItemText primary={text} primaryTypographyProps={{ variant: "body2", fontWeight: "medium" }} />
    </ListItem>
  );
};

const ArmilisSidebar = ({ drawerWidth, openQRCodeScannerDrawer }) => {
  const { user, isReseller, canAddUser, conservSostL1, isInternal } = UserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const handleNavigation = (item) => {
    if (item.route && location.pathname !== item.route) {
      navigate(item.route);
    } else if (item.onClick) {
      item.onClick();
    }
  };

  const menuItems = [
    {
      text: conservSostL1 ? t("my_archives") : t("my_databoxes"),
      icon: <ArchiveIcon />,
      route: "/",
    },
    {
      text: t("my_groups"),
      icon: <GroupsIcon />,
      route: "/my-groups",
    },
    !conservSostL1 && {
      text: conservSostL1 ? t("latest_documents") : t("latest_certifications"),
      icon: <PlaylistAddCheckOutlinedIcon />,
      route: "/my-certifications",
    },
    { isDivider: true },
    isReseller && {
      text: t("reseller_panel"),
      icon: <ApiIcon />,
      route: "/reseller-panel",
    },
    isMobile && {
      text: conservSostL1 ? t("scan_archive") : t("scan_databox"),
      icon: <QrCodeScannerIcon />,
      onClick: openQRCodeScannerDrawer,
    },
    !conservSostL1 && {
      text: t("generate_keypair"),
      icon: <KeyIcon />,
      route: "/keypair-generator",
    },
    canAddUser && {
      text: t("add_new_user"),
      icon: <PersonAddIcon />,
      route: "/new-user",
    },
    isInternal && {
      text: t("customer_records"),
      icon: <BusinessIcon />,
      route: "/customers",
    },
    false && {
      text: t("monitor_system"),
      icon: <MonitorHeartIcon />,
      route: "/system-consumption",
    },
  ].filter(Boolean);

  return (
    <Drawer
      variant="permanent"
      open
      sx={{
        width: drawerWidth,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#f5f5f5",
          borderRight: "1px solid #e0e0e0",
        },
      }}
    >
      <Toolbar />
      <List>
        {user ? (
          menuItems.map((item, index) =>
            item.isDivider ? <Divider key={index} sx={{ my: 1 }} /> : <NavigationItem key={index} onClick={() => handleNavigation(item)} icon={item.icon} text={item.text} />
          )
        ) : (
          <>
            <NavigationItem onClick={() => navigate("/signin")} icon={<LoginIcon />} text={t("sign_in")} />
            <NavigationItem onClick={() => navigate("/keypair-generator")} icon={<KeyIcon />} text={t("generate_keypair")} />
          </>
        )}
      </List>
      <Box sx={{ p: 2, mt: "auto" }}>
        <Copyright />
      </Box>
    </Drawer>
  );
};

export default ArmilisSidebar;
