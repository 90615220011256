// react
import React from "react";

// react-router-dom
import { Link } from "react-router-dom";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import { Grid, Typography, Divider, Stack } from "@mui/material";

// A ---------------------------------------------------------------------- M

const AccountCard = () => {
  const { user, clientData } = UserAuth();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} data-testid="account-information-card">
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
          {t("account_information")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mb: 1 }} />
        <Stack spacing={0.5}>
          <Typography variant="body2">
            <strong>{t("user_id")}</strong>: {user.uid || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>{t("email")}</strong>: {user.email || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>{t("customer")}</strong>:{" "}
            {clientData ? (
              <Link to={`/customers/${clientData.id}`} state={{ client: clientData }}>
                {clientData.companyName || clientData.id}
              </Link>
            ) : (
              "N/A"
            )}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AccountCard;
