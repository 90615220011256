// react
import React, { useState } from "react";

// react-router-dom
import { useNavigate } from "react-router-dom";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { useMediaQuery, Grid, Typography, Button, Stack, IconButton, Accordion, AccordionSummary, AccordionDetails, Divider, Snackbar, Alert, Box, Paper, Slide } from "@mui/material";
import {
  NotificationAdd as NotificationAddIcon,
  NotificationsNone as NotificationsNoneIcon,
  QrCode as QrCodeIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Settings as SettingsIcon,
  Stream as StreamIcon,
  ExpandMore as ExpandMoreIcon,
  CallReceived as CallReceivedIcon,
  AlarmAdd as AlarmAddIcon,
  AlarmOff as AlarmOffIcon,
} from "@mui/icons-material";

// ui-components
import TagQRCode from "./TagQRCode";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const Transition = (props) => <Slide {...props} direction="left" />;

const primaryGradient = "linear-gradient(45deg, #2196f3, #21cbf3)";

const buttonStyle = {
  background: primaryGradient,
  color: "#fff",
  boxShadow: "none",
  textTransform: "none",
  fontWeight: 500,
  borderRadius: 10,
  "&:hover": { background: "linear-gradient(45deg, #21cbf3, #2196f3)" },
};

const TagToolbar = ({ tag, isFollowing, handleFollowToggle, alarmOn, handleAlarmToggle }) => {
  const { user, conservSostL1 } = UserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [openQR, setOpenQR] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const handleQRCode = () => {
    setOpenQR(true);
    window.history.pushState(null, "");
  };

  const handleAccordionToggle = () => {
    setAccordionOpen((prev) => !prev);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarOpen(false);
    setTimeout(() => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    }, 100);
  };

  const handleFollowToggleWithSnackbar = () => {
    handleFollowToggle();
    showSnackbar(isFollowing ? t("unfollow_success") : t("follow_success"), isFollowing ? "info" : "success");
  };

  const handleAlarmToggleWithSnackbar = () => {
    handleAlarmToggle();
    showSnackbar(alarmOn ? t("alarm_disabled_success") : t("alarm_enabled_success"), alarmOn ? "info" : "success");
  };

  // Componente per mostrare le informazioni del tag in modo minimal
  const TagInformationCard = () => (
    <Box>
      <Stack spacing={0.5}>
        <Typography variant="body2">
          <strong>{t("id")}:</strong> {tag.id}
        </Typography>
        <Typography variant="body2">
          <strong>{t("name")}:</strong> {tag.name || "N/A"}
        </Typography>
        <Typography variant="body2">
          <strong>{t("description")}:</strong> {tag.notes || "N/A"}
        </Typography>
        <Typography variant="body2">
          <strong>{t("registration_datetime")}:</strong> {tag.firstseen ? convertTimestamp(tag.firstseen) : "N/A"}
        </Typography>
        <Typography variant="body2">
          <strong>{t("owner")}:</strong> {tag.tagOwnerEmail || "N/A"}
        </Typography>
        {conservSostL1 && (
          <Typography variant="body2">
            <strong>{t("document_type")}:</strong> {tag.tipologiaDocumentale || "N/A"}
          </Typography>
        )}
      </Stack>
    </Box>
  );

  const renderButtons = () => {
    if (isMobile) {
      return (
        <Paper
          elevation={1}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            p: 1,
            mt: 2,
            borderRadius: 1,
            backgroundColor: "background.default",
          }}
        >
          <IconButton color="primary" onClick={() => navigate(`/${tag.id}`)}>
            <PlaylistAddCheckIcon fontSize="medium" />
          </IconButton>
          {tag.tagOwner === user.uid && (
            <>
              <IconButton color="primary" onClick={() => navigate(`/${tag.id}/requests`)}>
                <CallReceivedIcon fontSize="medium" />
              </IconButton>
              <IconButton color="primary" onClick={() => navigate(`/${tag.id}/settings`)}>
                <SettingsIcon fontSize="medium" />
              </IconButton>
            </>
          )}
          {!conservSostL1 && (
            <IconButton color="primary" onClick={() => navigate(`/${tag.id}/monitor`)}>
              <StreamIcon fontSize="medium" />
            </IconButton>
          )}
          <IconButton color="primary" onClick={handleFollowToggleWithSnackbar}>
            {isFollowing ? <NotificationsNoneIcon fontSize="medium" /> : <NotificationAddIcon fontSize="medium" />}
          </IconButton>
          <IconButton color="primary" onClick={handleAlarmToggleWithSnackbar}>
            {alarmOn ? <AlarmOffIcon fontSize="medium" /> : <AlarmAddIcon fontSize="medium" />}
          </IconButton>
          <IconButton color="primary" onClick={handleQRCode}>
            <QrCodeIcon fontSize="medium" />
          </IconButton>
        </Paper>
      );
    } else {
      return (
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button startIcon={<PlaylistAddCheckIcon fontSize="medium" />} variant="contained" onClick={() => navigate(`/${tag.id}`)} sx={buttonStyle}>
              {conservSostL1 ? t("documents") : t("certifications")}
            </Button>
          </Grid>
          {tag.tagOwner === user.uid && (
            <>
              {!conservSostL1 && (
                <Grid item>
                  <Button startIcon={<CallReceivedIcon fontSize="medium" />} variant="contained" onClick={() => navigate(`/${tag.id}/requests`)} sx={buttonStyle}>
                    {t("requests")}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button startIcon={<SettingsIcon fontSize="medium" />} variant="contained" onClick={() => navigate(`/${tag.id}/settings`)} sx={buttonStyle}>
                  {t("settings")}
                </Button>
              </Grid>
            </>
          )}
          {!conservSostL1 && (
            <Grid item>
              <Button startIcon={<StreamIcon fontSize="medium" />} variant="contained" onClick={() => navigate(`/${tag.id}/monitor`)} sx={buttonStyle}>
                {t("monitor")}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              startIcon={isFollowing ? <NotificationsNoneIcon fontSize="medium" /> : <NotificationAddIcon fontSize="medium" />}
              variant="contained"
              onClick={handleFollowToggleWithSnackbar}
              sx={buttonStyle}
            >
              {isFollowing ? t("unfollow") : t("follow")}
            </Button>
          </Grid>
          {!conservSostL1 && (
            <Grid item>
              <Button startIcon={alarmOn ? <AlarmOffIcon fontSize="medium" /> : <AlarmAddIcon fontSize="medium" />} variant="contained" onClick={handleAlarmToggleWithSnackbar} sx={buttonStyle}>
                {alarmOn ? t("disable_alarm") : t("enable_alarm")}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button startIcon={<QrCodeIcon fontSize="medium" />} variant="contained" onClick={handleQRCode} sx={buttonStyle}>
              {t("qr_code")}
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion
            expanded={accordionOpen}
            onChange={handleAccordionToggle}
            sx={{
              boxShadow: "none",
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" fontWeight="bold">
                {tag.name || "N/A"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider sx={{ mb: 1 }} />
              <TagInformationCard />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          {renderButtons()}
        </Grid>
      </Grid>

      <TagQRCode tag={tag.id} open={openQR} setOpen={setOpenQR} />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        TransitionComponent={Transition}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ mt: { md: "2.5%" } }}
      >
        <Alert
          severity={snackbarSeverity}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          sx={{
            width: "100%",
            borderRadius: 1,
            py: 1,
            px: 2,
            display: "flex",
            alignItems: "center",
            fontSize: "1rem",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TagToolbar;
