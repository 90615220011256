// firebase
import { collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const editCustomerPolicy = async (client, tipologiaDocumentale, condition) => {
  try {
    const { id: clientID } = client;

    const agreementsRef = collection(db, "clientsdata", clientID, "agreements");
    const q = query(agreementsRef, where("tipologiaDocumentale", "==", tipologiaDocumentale));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.error(`No agreement found for client ID ${clientID} with document type ${tipologiaDocumentale}`);
      return;
    }

    const agreementDoc = querySnapshot.docs[0];
    await updateDoc(agreementDoc.ref, { condition });
    console.log(`Client policy for client ID ${clientID} and document type ${tipologiaDocumentale} updated successfully.`);
  } catch (error) {
    console.error(`Error updating client policy for client ID ${client.id} and document type ${tipologiaDocumentale}:`, error);
  }
};

export default editCustomerPolicy;
