// firebase
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

export const setCanCreateDataboxFlag = async (keyID) => {
  try {
    const docRef = doc(db, "keysecrets", keyID);

    await updateDoc(docRef, {
      canCreateDatabox: true,
    });

    console.log(`Document with ID ${keyID} successfully updated.`);
  } catch (error) {
    console.error(`Error in setCanCreateDataboxFlag for keyID ${keyID}:`, error);
  }
};

export const unsetCanCreateDataboxFlag = async (keyID) => {
  try {
    const docRef = doc(db, "keysecrets", keyID);

    await updateDoc(docRef, {
      canCreateDatabox: false,
    });

    console.log(`Document with ID ${keyID} successfully updated.`);
  } catch (error) {
    console.error(`Error in unsetCanCreateDataboxFlag for keyID ${keyID}:`, error);
  }
};
